<template>
  <div class="message">
    <div class="home-message" @click="dialogFormVisible = true">
      <span>用户留言</span>
      <span><img src="@/assets/img/home/img-home-message.png" alt=""/></span>
    </div>
    <el-dialog class="home-message-dialog" title="客户留言" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="formName" :rules="rules">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号"> </el-input>
        </el-form-item>
        <el-form-item label="留言" prop="message">
          <el-input
            v-model="form.message"
            type="textarea"
            :rows="7"
            placeholder="请输入留言"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="save()" type="primary">确 定</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (this.checkMobile(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        phone: "",
        message: ""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        message: [{ required: true, message: "请输入留言", trigger: "blur" }]
      }
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    form: {
      handler(newValue){
        if(!newValue.phone.length){
          this.$refs['form'].clearValidate('phone');
        }
      },
      deep: true
    }
  },
  methods: {
    // 验证手机号
    checkMobile(str) {
      let reg = /^1[3-9][0-9]\d{8}$/;
      if (reg.test(str)) {
        return true;
      } else {
        return false;
      }
    },

    save() {
      this.$refs.formName.validate(valid => {
        console.log(valid);
        if (valid) {
          this.$api
            .postMessageAdd({
              message: this.form.message,
              mobile: this.form.phone,
              name: this.form.name
            })
            .then(res => {
              let { msg, code } = res;
              if (code == 200) {
                this.dialogFormVisible = false;
                this.form.message = "";
                this.form.phone = "";
                this.form.name = "";
                this.$message({
                  type: "success",
                  message: msg,
                  duration: 1000
                });
              }
            });
        }
      });
    }
    // save() {
    //   this.$refs.formName.validate(valid => {
    //     console.log(valid);
    //     if (valid) {
    //       this.$api.postMessageAdd(this.form).then(res => {
    //         let { msg, code } = res;
    //         if (code == 200) {
    //           this.dialogFormVisible = false;
    //           this.$message({
    //             type: "success",
    //             message: msg,
    //             duration: 1000
    //           });
    //         }
    //       });
    //     }
    //   });
    // }
  },
  components: {}
};
</script>

<style lang="less">
@import "./index.less";
</style>
