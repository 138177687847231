/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 19:50:05
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-09 17:04:43
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "@/store/getters";

Vue.use(Vuex);

// webpack中的#require.context
const modulesFiles = require.context("./modules", true, /\.js$/);

/**
 * 不需要import app from ./modules/app
 * 它将自动从模块文件中要求所有的vuex模块
 */
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
  getters
});

export default store;
