/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:41:18
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-11-09 17:33:25
 */
//
export const routes = [
  {
    path: "*",
    component: () => import("../layout"),
    redirect: "home",
    children: [
      {
        path: "/login",
        component: () => import("../views/login")
      },
      {
        path: "/register",
        component: () => import("../views/register")
      },
      {
        path: "home",
        name: "Home",
        meta: {
          title: "首页"
        },
        component: () => import("../views/home"),
        children: []
      },
      {
        path: "grab-single",
        name: "grabSingle",
        meta: {
          title: "抢单大厅"
        },
        component: () => import("../views/grabSingle"),
        children: []
      },
      {
        path: "grab-single/detail",
        name: "grabSingle",
        meta: {
          title: "抢单详情"
        },
        component: () => import("../views/grabSingle/detail/index.vue"),
        children: []
      },
      // {
      //   path: "grab-single/detail",
      //   name: "grabSingle",
      //   meta: {
      //     title: "抢单详情"
      //   },
      //   component: () => import("../views/grabSingle/detail/index.vue"),
      //   children: []
      // },
      {
        path: "product",
        name: "product",
        meta: {
          title: "产品信息"
        },
        component: () => import("../views/product"),
        children: []
      },
      {
        path: "product/detail",
        name: "product",
        meta: {
          title: "产品详情"
        },
        component: () => import("../views/product/detail/index.vue"),
        children: []
      },
      {
        path: "product/detail/payment",
        name: "product",
        meta: {
          title: "产品购买",
          requireAuth: true
        },
        component: () => import("../views/product/detail/payment/index.vue"),
        children: []
      },
      {
        path: "customer-case",
        name: "customerCase",
        meta: {
          title: "客户案例"
        },
        component: () => import("../views/customerCase"),
        children: []
      },
      {
        path: "customer-case/detail",
        name: "customerCaseDetail",
        meta: {
          title: "案例详情"
        },
        component: () => import("../views/customerCase/detail/index.vue"),
        children: []
      },
      {
        path: "information",
        name: "information",
        meta: {
          title: "平台资讯"
        },
        component: () => import("../views/information/index.vue"),
        children: []
      },
      {
        path: "information/detail",
        name: "detail",
        meta: {
          title: "咨询活动"
        },
        component: () => import("../views/information/detail/index.vue"),
        children: []
      },
      {
        path: "marketing",
        name: "marketing",
        meta: {
          title: "营销活动"
        },
        component: () => import("../views/marketing"),
        children: []
      },
      {
        path: "marketing/detail",
        name: "marketing",
        meta: {
          title: "活动详情"
        },
        component: () => import("../views/marketing/detail/index.vue"),
        children: []
      },
      {
        path: "platform",
        name: "platform",
        meta: {
          title: "平台介绍"
        },
        component: () => import("../views/platform"),
        children: []
      },
      {
        path: "platform/details",
        name: "platform-details",
        meta: {
          title: "平台介绍-详情"
        },
        component: () => import("../views/platform/details"),
        children: []
      },
      {
        path: "wallet/details",
        name: "wallet-details",
        meta: {
          title: "平台介绍-详情"
        },
        component: () => import("../views/platform/details"),
        children: []
      },
      {
        path: "install-tutorial",
        name: "install-tutorial",
        meta: {
          title: "安装教程"
        },
        component: () => import("../views/installTutorial"),
        children: []
      },
      {
        path: "personal-center",
        name: "personalCenter",
        meta: {
          color: 1,
          title: "个人中心",
          requireAuth: true
        },
        component: () => import("../views/personalCenter"),
        redirect: "/personal-center/user/info",
        children: [
          {
            path: "user/info",
            name: "userInfo",
            meta: {
              title: "个人信息-我的资料",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/userInfo")
          },
          {
            path: "user/pass",
            name: "updatePass",
            meta: {
              title: "个人信息-更改密码",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/updatePass")
          },
          {
            path: "earnings",
            name: "earnings",
            meta: {
              title: "收益统计",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/earnings")
          },
          {
            path: "balance/wallet",
            name: "balanceWallet",
            meta: {
              title: "余额-钱包余额",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/balance/wallet")
          },
          {
            path: "cashDeposit",
            name: "cashDeposit",
            meta: {
              title: "保证金",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/cashDeposit")
          },
          {
            path: "balance/purchase",
            name: "balancePurchase",
            meta: {
              title: "余额-采购结余",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/balance/purchase")
          },
          {
            path: "install/statistical",
            name: "installStatistical",
            meta: {
              title: "安装统计-订单统计",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/install/statistical")
          },
          {
            path: "install/signit",
            name: "installSignIt",
            meta: {
              title: "安装统计-待签收",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/install/signIt")
          },

          {
            path: "install/inservice",
            name: "inService",
            meta: {
              title: "安装统计-服务中",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/install/inService")
          },
          {
            path: "install/appointment",
            name: "appointment",
            meta: {
              title: "安装统计-已预约",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/install/appointment")
          },
          {
            path: "install/complete",
            name: "complete",
            meta: {
              title: "安装统计-已完成",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/install/complete")
          },
          {
            path: "install/evaluation",
            name: "settlement",
            meta: {
              title: "安装统计-订单评价",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/install/evaluation")
          },
          {
            path: "install/settlement",
            name: "settlement",
            meta: {
              title: "安装统计-已结算",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/install/settlement")
          },
          {
            path: "install/reorder",
            name: "reorder",
            meta: {
              title: "安装统计-返单",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/install/reorder")
          },
          {
            path: "purchase/place-order",
            name: "placeOrder",
            meta: {
              title: "安装统计-已下单",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/purchase/placeOrder")
          },
          {
            path: "purchase/shipped",
            name: "shipped",
            meta: {
              title: "安装统计-已发货",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/purchase/shipped")
          },
          {
            path: "purchase/completed",
            name: "completed",
            meta: {
              title: "安装统计-已发货",
              color: 1
            },
            component: () =>
              import("../views/personalCenter/purchase/completed")
          },
          {
            path: "purchase/refund",
            name: "refund",
            meta: {
              title: "安装统计-退货",
              color: 1,
              requireAuth: true
            },
            component: () => import("../views/personalCenter/purchase/refund")
          },
          {
            path: "purchase/shipping-address",
            name: "shippingAddress",
            meta: {
              title: "安装统计-收货地址",
              color: 1,
              requireAuth: true
            },
            component: () =>
              import("../views/personalCenter/purchase/shippingAddress")
          }
        ]
      },
      {
        path: "/personal-center/install/details",
        name: "installDetails",
        meta: {
          title: "安装统计-详情",
          color: 1,
          requireAuth: true
        },
        component: () => import("../views/personalCenter/install/details")
      },
      {
        path: "/personal-center/install/accessories-details",
        name: "installDetails",
        meta: {
          title: "安装统计-待签收",
          color: 1,
          requireAuth: true
        },
        component: () =>
          import("../views/personalCenter/install/accessoriesDetails")
      },
      {
        path: "/personal-center/install/back-details",
        name: "installDetails",
        meta: {
          title: "安装统计-返单详情",
          color: 1,
          requireAuth: true
        },
        component: () => import("../views/personalCenter/install/backDetails")
      },
      {
        path: "/personal-center/purchase/details",
        name: "purchaseDetails",
        meta: {
          title: "安装统计-待签收",
          color: 1,
          requireAuth: true
        },
        component: () => import("../views/personalCenter/purchase/details")
      }
    ]
  }
];
