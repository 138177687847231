<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 09:14:34
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-18 15:41:10
-->
<template>
  <div id="app" ref="main">
    <!-- <el-button type="">asd1</el-button> -->
    <router-view />
    <Message ></Message>
  </div>
</template>

<script>
import axios from "axios";
import Message from './components/leaveMessage/index.vue'
export default {
  components:{Message},
  watch: {
    $route() {
      let main = this.$refs.main;
      main.scrollTo(0, 0);
    }
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    getAddress() {
      let sefl = this;
      // 为给定 ID 的 user 创建请求
      axios
        .get(process.env.VUE_APP_ADDRESS_URL)
        .then(function(response) {
          if (response.status == 200) {
            sefl.$store.dispatch("setAddress", response.data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      // axios
      //   .get(process.env.VUE_APP_ADDRESS_ALL_URL)
      //   .then(function(response) {
      //     if (response.status == 200) {
      //       sefl.$store.dispatch("setAddressAll", response.data);
      //     }
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //   });
    }
  }
};
</script>

<style lang="less">
@import "./theme/index.less";
@import "//at.alicdn.com/t/font_2762218_e7hstwavrb.css";
// @import "./assets/iconfont/iconfont.css";

#app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
/*滚动条样式*/
#app::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0.6rem;
}
#app::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0.05rem;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.8);
  background: #3072f6;
}
#app::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: transparent;
}
</style>
