/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-04-27 15:34:32
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-10 16:01:06
 */
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line"); //线型图
require("echarts/lib/component/dataZoom"); //dataZoom组件
require("echarts/lib/component/tooltip"); //tooltip
require("echarts/lib/component/legend"); //图例

// import echarts from "echarts";

const install = function(Vue) {
  Object.defineProperties(Vue.prototype, {
    $echarts: {
      get() {
        return {
          initCharts: function(chartsInstance, chartsRef, chartsOptions) {
            const renderedInstance = echarts.init(chartsRef);
            if (renderedInstance) {
              chartsInstance = renderedInstance;
            } else {
              chartsInstance = echarts.init(chartsRef);
            }
            chartsInstance.setOption(chartsOptions);
            return chartsInstance;
          }
        };
      }
    }
  });
};

export default {
  install
};
