/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-09 16:57:01
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-18 15:26:32
 */
const getters = {
  user: state => state.users.user,
  isPower: state => state.users.isPower,
  userId: state => state.users.user.id,
  address: state => state.address.list,
  addressAll: state => state.address.allList
};
export default getters;
