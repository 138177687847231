/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-09 11:22:05
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-13 10:43:49
 */
const LOGIN_USERS_KEY = "LOGIN_USERS_KEY";
const LOGIN_USERS_ID_KEY = "LOGIN_USERS_ID_KEY";
const LOGIN_USERS_TOKEN_KEY = "LOGIN_USERS_TOKEN_KEY";
const FULL_PATH = "FULL_PATH";
const INFOR_MATION = "INFOR_MATION";

// const sessionStorage = window.sessionStorage; // 会话存储
const localStorage = window.localStorage; // 持久化存储

/**
 * 设置缓存
 * @param {String} key 键名
 * @param {any} data 缓存数据
 * @param {Number} expires 过期时间 单位 s
 */
function setLocal(key, data, expires = 3600) {
  const date = new Date();
  try {
    localStorage.setItem(
      key,
      JSON.stringify({
        expires: date.valueOf() + expires * 1000,
        data: data
      })
    );
  } catch (e) {
    if (e.name === "QuotaExceededError") {
      localStorage.clear();
      setLocal(key, data, expires);
    }
  }
}

/**
 * 获取缓存
 * @param key
 * @return {any}
 */
function getLocal(key) {
  const result = JSON.parse(localStorage.getItem(key));
  const date = new Date();
  if (result && result.expires > date.valueOf()) {
    return result.data;
  } else {
    removeLocal(key);
    return null;
  }
}

/**
 * 删除键
 * @param key
 */
function removeLocal(key) {
  localStorage.removeItem(key);
}

export const session = {
  // 获取登录用户信息
  getUsers() {
    // localStorage.clear()
    return getLocal(LOGIN_USERS_KEY);
  },
  // 设置登录用户信息
  setUsers(data) {
    // 86400秒 = 1天
    setLocal(LOGIN_USERS_KEY, data, 86400);
  },
  // 清空登录用户信息
  removeUsers() {
    removeLocal(LOGIN_USERS_KEY);
  },

  // 获取token
  getLoginToken() {
    return getLocal(LOGIN_USERS_TOKEN_KEY);
  },
  // 设置token
  setLoginToken(data) {
    // 86400秒 = 1天
    setLocal(LOGIN_USERS_TOKEN_KEY, data, 84600);
  },
  // 清空token
  removeLoginToken() {
    removeLocal(LOGIN_USERS_TOKEN_KEY);
  },

  // 获取登录用户信息
  getUsersId() {
    return getLocal(LOGIN_USERS_ID_KEY);
  },
  // 设置登录用户信息
  setUsersId(data) {
    setLocal(LOGIN_USERS_ID_KEY, data, 86400);
  },
  // 清空登录用户信息
  removeUsersId() {
    removeLocal(LOGIN_USERS_ID_KEY);
  },

  // 获取登录后重定向地址
  getFullPath() {
    return getLocal(FULL_PATH);
  },
  // 设置登录后重定向地址
  setFullPath(data) {
    // 2538000秒 = 30天
    setLocal(FULL_PATH, data, 2538000);
  },
  // 清空登录后重定向地址
  removeFullPath() {
    removeLocal(FULL_PATH);
  },
  // 设置平台咨讯列表
  set_informationList(data) {
    setLocal(INFOR_MATION, data);
  },
  // 获取平台咨讯列表
  get_informationList() {
    return getLocal(INFOR_MATION);
  },
  // 获取平台咨讯列表
  remove_informationList() {
    removeLocal(INFOR_MATION);
  }
};
