/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-17 08:51:25
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-18 15:29:23
 */

const state = {
  list: [],
  allList: []
};

const mutations = {
  SET_ADDRESS(state, data) {
    state.list = data;
  },
  SET_ADDRESS_ALL(state, data) {
    state.allList = data;
  }
};

const actions = {
  setAddress(context, data) {
    context.commit("SET_ADDRESS", data);
  },
  setAddressAll(context, data) {
    context.commit("SET_ADDRESS_ALL", data);
  }
};

export default {
  namespaced: false,
  state,
  mutations,
  actions
};
