/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-13 14:54:25
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-11-17 09:19:06
 */
export default {
  /**
   * 图片地址拼接
   * @param {*} img 图片地址
   * @returns
   */
  picturePrefix(img = "") {
    if (!img) {
      return "-";
    }
    // 前缀+传入的图片地址
    return `${process.env.VUE_APP_IMG_URL}${img}`;
  },

  /**
   * 姓名格式化 只显示第一位，后面的*代替
   * @param {*} name 姓名
   * @returns
   */
  formattedName(name = "") {
    name = name || "";
    name = name.toString();
    return name.substring(0, 1) + new Array(name.length).join("*");
  },

  /**
   * 手机号码格式化中间4位显示*
   * @param {*} phone 手机号码
   * @returns
   */
  formattedPhone(phone = "") {
    phone = phone || "";
    phone = phone.toString();
    return phone.replace(phone.substr(3, 4), "****");
  },
  /**
   * 价格格式化
   * @param {*} number 价格
   * @param {*} places 保留小数位数
   * @param {*} thousand 千位分隔符
   * @param {*} decimal 小数分隔符
   * @returns
   */
  formatMoney(number = 0, places = 2, thousand = ",", decimal = ".") {
    thousand = thousand || ",";
    decimal = decimal || ".";
    var negative = number < 0 ? "-" : "",
      i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      negative +
      (j ? i.substr(0, j) + thousand : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
      (places
        ? decimal +
          Math.abs(number - i)
            .toFixed(places)
            .slice(2)
        : "")
    );
  },
  /**
   * 订单类型转换
   * @param {*} type
   * @returns
   */
  orderTypeName(type) {
    if (type == 1) {
      return "安装单";
    } else if (type == 2) {
      return "配件单";
    }
    return "-";
  },

  // orderCommentsList
  /**
   * 多维数组转二位数组
   * @param {*} params
   */
  moreOrTwoArr(params) {
    let newTotalArr = [];
    let self = this;

    function moreArr(oldArr, newArr) {
      oldArr.map(item => {
        newArr.push({
          accessoriesName: item.accessoriesName,
          accessoriesNum: item.accessoriesNum,
          customerId: item.customerId,
          customerName: item.customerName,
          createDate: item.createDate,
          info: item.info,
          orderId: item.orderId,
          pid: item.pId,
          photo: self.picturePrefix(item.photo),
          type: item.type,
          id: item.id
        });
        if (item.orderCommentsList) {
          moreArr(item.orderCommentsList, newArr);
        }
      });
    }

    params.map(item => {
      let json = {
        accessoriesName: item.accessoriesName,
        accessoriesNum: item.accessoriesNum,
        customerId: item.customerId,
        customerName: item.customerName,
        createDate: item.createDate,
        info: item.info,
        photo: this.picturePrefix(item.photo),
        orderId: item.orderId,
        pid: item.pId,
        type: item.type,
        id: item.id
      };

      if (item.orderCommentsList) {
        let newArr = [];
        moreArr(item.orderCommentsList, newArr);
        json.orderCommentsList = newArr;
      }
      newTotalArr.push(json);
    });

    return newTotalArr;
  },

  /**
   * 时间格式化
   * @param {*} date 时间或者时间字符串
   * @param {*} format 时间格式
   * @returns 格式化后的时间字符串
   */
  dateFormat(date, format = "yyyy-mm-dd") {
    let dt = date;
    if (!(date instanceof Date)) {
      if (typeof date == "string") {
        dt = new Date(Date.parse(date.replace(/-/g, "/")));
      } else if (date instanceof Number) {
        dt = new Date(date);
      }
    }
    if (dt == "Invalid Date") {
      return "-";
    }
    let y = dt.getFullYear();
    let m = (dt.getMonth() + 1).toString().padStart(2, "0");
    let d = dt
      .getDate()
      .toString()
      .padStart(2, "0");
    let h = dt
      .getHours()
      .toString()
      .padStart(2, "0");
    let mm = dt
      .getMinutes()
      .toString()
      .padStart(2, "0");
    let s = dt
      .getSeconds()
      .toString()
      .padStart(2, "0");
    if (
      format.toLocaleLowerCase() === "yyyy-mm-dd" ||
      format.toLocaleLowerCase() === ""
    ) {
      return `${y}-${m}-${d}`;
    } else if (format.toLocaleLowerCase() === "hh:mm") {
      return `${h}:${mm}`;
    } else if (format.toLocaleLowerCase() === "mm-dd") {
      return `${m}-${d}`;
    } else if (format.toLocaleLowerCase() === "dd") {
      return `${d}`;
    } else if (format.toLocaleLowerCase() === "yyyy-mm") {
      return `${y}-${m}`;
    } else if (format.toLocaleLowerCase() === "yyyy年mm月") {
      return `${y}年${m}月`;
    } else if (format.toLocaleLowerCase() === "mm月dd日 hh:mm") {
      return `${m}月${d}日 ${h}:${mm}`;
    } else {
      return `输入的时间格式有误`;
    }
  }
};
