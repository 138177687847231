/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-09 11:32:38
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-09-09 11:37:16
 */
import { session } from "./sessions";

//权限判断
export const permissions = () => {
  let user = session.getUsers();
  let token = session.getLoginToken();

  if (user && token) {
    return true;
  }
  return false;
};
