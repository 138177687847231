/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-09 16:51:36
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-06 18:02:26
 */
import { session } from "@/utils/sessions";
import { permissions } from "@/utils/permissions";
import api from "@/api/api";
import axios from "axios";

const state = {
  user: session.getUsers(),
  isPower: permissions()
};

const mutations = {
  GET_USER(state, isNotUser) {
    console.log("isNotUser", isNotUser);
    let users = session.getUsers();
    if (typeof users.address == "string") {
      let area = [];
      let address = users.address.split(",");
      users.address = [];
      axios
        .get(process.env.VUE_APP_ADDRESS_URL)
        .then(function(response) {
          if (response.status == 200) {
            let addressArr = [...response.data];
            address.map(item => {
              let addressJson = addressArr.find(nape => {
                return nape.label == item;
              });
              if (addressJson) {
                area.push(addressJson.value);
                if (addressJson.children) {
                  addressArr = [...addressJson.children];
                }
              }
            });
            users.address = area;
            state.user = users;
            console.log("area", area);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }

    state.user = users;
    state.isPower = permissions();
    if (permissions() && !isNotUser) {
      api.getUserInfo({ id: users.id }).then(user => {
        session.setUsers(user.data);
        let area = [];
        let address = user.data.address.split(",");
        users.address = [];
        axios
          .get(process.env.VUE_APP_ADDRESS_URL)
          .then(function(response) {
            if (response.status == 200) {
              let addressArr = [...response.data];
              address.map(item => {
                let addressJson = addressArr.find(nape => {
                  return nape.label == item;
                });
                if (addressJson) {
                  area.push(addressJson.value);
                  if (addressJson.children) {
                    addressArr = [...addressJson.children];
                  }
                }
              });
              users.address = area;
              state.user = users;
              console.log("area", area);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
        state.user = user.data;
      });
    }
  },
  SET_USER_NULL(state) {
    state.user = {};
    state.isPower = false;
  }
};

const actions = {
  getUser(context, isNotUser) {
    context.commit("GET_USER", isNotUser);
  },
  setUserNull(context) {
    context.commit("SET_USER_NULL");
  }
};

export default {
  namespaced: false,
  state,
  mutations,
  actions
};
