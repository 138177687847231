/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-10 09:02:54
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-17 09:45:44
 */
import axios from "axios";
import { session } from "../utils/sessions";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "X-Requested-With": "XMLHttpRequest"
  }
});
//请求拦截
instance.interceptors.request.use(
  config => {
    config.headers["token"] = session.getLoginToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 请求返回后拦截
instance.interceptors.response.use(
  response => {
    const { code } = response.data;
    if (code !== 200) {
      if (Number(code) === 400) {
        localStorage.clear(); // 用户失效清楚所有缓存
        location.href = "/water-info/login";
      }
      return Promise.reject(response);
    }
    return response;
  },
  error => {
    console.log("catch", error);
    return Promise.reject(error);
  }
);

export default instance;
