/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 09:14:34
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-11 14:55:10
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import element from "./utils/element";
import "element-ui/lib/theme-chalk/index.css";
import api from "./api/api";
import utils from "./utils/utils";
import initCharts from "@/utils/initCharts";
import VideoPlayer from "vue-video-player";
import SignCanvas from "sign-canvas";
import drag from "./utils/drag";
// import "./utils/messages";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");

Vue.use(SignCanvas);
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;
Vue.use(element);
Vue.use(initCharts);
Vue.use(VideoPlayer);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
