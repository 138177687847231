/*
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-08-30 09:14:34
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-19 11:58:20
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./config";

import { permissions } from "@/utils/permissions";
import { session } from "@/utils/sessions";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? process.env.BASE_URL : "/water-info",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (to.meta.requireAuth) {
      if (permissions()) {
        next();
        return;
      }
      session.setFullPath(to.fullPath);
      next("/login");
      setTimeout("console.clear()", 300);
      return;
    }
    next();
    return;
  }
  next();
});

export default router;
