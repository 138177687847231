import {
  Select,
  Option,
  OptionGroup,
  Input,
  InputNumber,
  Radio,
  Tree,
  Dialog,
  Row,
  Col,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Cascader,
  Switch,
  Slider,
  TimeSelect,
  TimePicker,
  DatePicker,
  Upload,
  Rate,
  ColorPicker,
  Transfer,
  Form,
  FormItem,
  Table,
  TableColumn,
  Tag,
  Button,
  Progress,
  Pagination,
  Badge,
  Avatar,
  Alert,
  Loading,
  Message,
  MessageBox,
  Notification,
  RadioGroup,
  RadioButton,
  Menu,
  MenuItemGroup,
  MenuItem,
  Submenu,
  Link,
  Container,
  Header,
  Aside,
  Footer,
  Dropdown,
  Main,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Empty,
  Popover,
  Timeline,
  TimelineItem,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane
} from "element-ui";

const element = {
  install: function(Vue) {
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);
    Vue.use(Input);
    Vue.use(InputNumber);
    Vue.use(Radio);
    Vue.use(Tree);
    Vue.use(Dialog);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(Cascader);
    Vue.use(Switch);
    Vue.use(Slider);
    Vue.use(TimePicker);
    Vue.use(TimeSelect);
    Vue.use(DatePicker);
    Vue.use(Upload);
    Vue.use(Rate);
    Vue.use(ColorPicker);
    Vue.use(Transfer);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Tag);
    Vue.use(Button);
    Vue.use(Progress);
    Vue.use(Pagination);
    Vue.use(Badge);
    Vue.use(Avatar);
    Vue.use(Alert);
    Vue.use(Loading);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Link);

    Vue.use(Menu);
    Vue.use(MenuItem);
    Vue.use(MenuItemGroup);
    Vue.use(Submenu);

    Vue.use(Container);
    Vue.use(Header);
    Vue.use(Aside);
    Vue.use(Footer);
    Vue.use(Main);

    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);

    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(Popover);
    Vue.use(Empty);
    Vue.use(Timeline);
    Vue.use(TimelineItem);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.prototype.$message = function(msg) {
      return Message({
        ...msg,
        duration: 1000
      });
    };
    Vue.prototype.$message.error = function(msg) {
      return Message({
        message: msg,
        type: "error",
        duration: 1000
      });
    };
    Vue.prototype.$message.success = function(msg) {
      return Message({
        message: msg,
        type: "success",
        duration: 1000
      });
    };
    Vue.prototype.$message.warning = function(msg) {
      return Message({
        message: msg,
        type: "warning",
        duration: 1000
      });
    };
    Vue.prototype.$message.info = function(msg) {
      return Message({
        message: msg,
        type: "info",
        duration: 1000
      });
    };
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$notify = Notification;
  }
};

export default element;
