/*
 * @Description:
 * @Autor: JF
 * @Date: 2021-04-29 10:29:17
 * @LastEditors: Zhongyu
 * @LastEditTime: 2022-01-13 15:57:55
 */
import http from "./http.js";

// import zApi from "./zAp";

// 登录

export default {
  // 登录
  login(params) {
    return http.post("/web/login", params);
  },
  // 注册
  registered(params) {
    return http.post("/web/registered", params);
  },
  // 获取验证码
  sendSms(params) {
    return http.get("/web/sendSms", params);
  },
  // 获取验证码
  getUserInfo(params) {
    return http.get("/userWeb/userInfo", params);
  },
  postMessageAdd(params) {
    return http.formPost("web/messageAdd", params);
  },
  // 抢单大厅
  getInstallationOrder(params) {
    return http.get("/web/installationOrder", params);
  },
  // 抢单大厅 详情  installationOrderType
  getInstallationOrderDetail(params) {
    return http.get(`/userWeb/installationOrderDetail?id=${params}`);
  },
  // 抢单大厅筛选分类
  getInstallationOrderType(params) {
    return http.get("/web/installationOrderType", params);
  },
  // 客户案列
  getCustomerCase(params) {
    return http.get("/web/customerCase", params);
  },
  // 客户案列详情
  getCustomerCaseDetail(params) {
    return http.get(`/web/customerCaseDetail?id=${params}`);
  },
  // 营销活动
  getMarketing(params) {
    return http.get("/web/marketing", params);
  },
  // 营销活动详情
  getMarketingDetail(params) {
    return http.get(`/web/marketingDetail?id=${params}`);
  },
  // 平台资讯
  getConsulting(params) {
    return http.get("/web/consulting", params);
  },
  // 平台资讯详情
  getConsultingDetail(params) {
    return http.get("/web/consultingDetail", params);
  },
  // 平台资讯 类型
  getConsultingType(params) {
    return http.get("/web/consultingType", params);
  },
  // 平台资讯类型   右侧
  getConsultingTypeSide(params) {
    return http.get("/web/consultingTypeSide", params);
  },
  // 产品列表
  getProduct(params) {
    return http.get("/web/product", params);
  },
  // 产品详情
  getProductDetail(params) {
    return http.get(`/web/productDetail?id=${params}`);
  },
  // 产品类型
  getProductType(params) {
    return http.get(`/web/productType?id=${params}`);
  },
  // 售卖详情
  getOrderDetail(params) {
    return http.get(`/userWeb/orderDetail?id=${params}`);
  },
  // 提交订单
  addOrder(params) {
    return http.post(`/userWeb/addOrder`, params);
  },
  // 立即抢单
  installationOrderGrab(params) {
    return http.get(
      `/userWeb/installationOrderGrab?id=${params.id}&userId=${params.userId}`
    );
  },
  //判断是否超出工作区
  isArea(params) {
    return http.get(`/userWeb/checkWorkArea`, params);
  },
  /**
   * 平台介绍
   * @returns
   */
  getUserGuide(params) {
    return http.get("/web/userGuide", params);
  },
  /**
   * 平台介绍
   * @returns
   */
  getIntroduce() {
    return http.get("/web/introduce");
  },

  /**
   * 所有的banner，
   * @param {type} params type 1首页2产品中心3客户案例4平台咨询5营销活动6平台介绍7安装教程
   * @returns
   */
  getBanner(params) {
    return http.get("/web/banner", params);
  },

  /**
   * 能匠服务订单
   * @returns
   */
  getServiceOrder() {
    return http.get("/web/serviceOrder");
  },

  /**
   * 能匠之心
   * @returns
   */
  getSellingOrder() {
    return http.get("/web/sellingOrder");
  },

  /**
   * 首页（产品，客户案例，新闻中心）数据
   * @returns
   */
  getHomeData() {
    return http.get("/web/homeData");
  },
  /**
   * 安装教程
   * @returns
   */
  getTutorial() {
    return http.get("/web/tutorial");
  },
  /**
   * 安装教程一级分类
   * @returns
   */
  getTutorialTypeOne() {
    return http.get("/web/tutorialTypeOne");
  },
  /**
   * 根据一级分类id获取安装教程分类
   * @returns
   */
  getTutorialType(params) {
    return http.get("/web/tutorialType", params);
  },
  /**
   * 修改用户信息
   * @param {*} params
   * @returns
   */
  changeCustomer(params) {
    return http.post("/userWeb/changeCustomer", params);
  },

  /**
   * 修改手机号
   * @param {*} params
   * @returns
   */
  changePhone(params) {
    return http.formPost("/userWeb/changePhone", params);
  },

  /**
   * 修改密码
   * @param {*} params
   * @returns
   */
  changePassword(params) {
    return http.formPost("/web/changePassword", params);
  },

  /**
   * 收益统计
   * @param {*} params
   * @returns
   */
  getEarningsStatistics(params) {
    return http.get("/userWeb/earningsStatistics", params);
  },

  /**
   * 收益统计-列表
   * @param {*} params
   * @returns
   */
  getEarningsStatisticsList(params) {
    return http.get("/userWeb/earningsStatisticsList", params);
  },

  /**
   * 申请提现
   * @param {*} params
   * @returns
   */
  setWallet(params) {
    return http.post("/userWeb/wallet", params);
  },

  /**
   * 账单-列表
   * @param {*} params
   * @returns
   */
  getWallet(params) {
    return http.get("/userWeb/wallet", params);
  },

  /**
   * 安装单-订单列表
   * @param {status} params 2待签收, 3服务中, 4已预约, 5待审核结算, 6已结算
   * @returns
   */
  getInstallOrders(params) {
    return http.get("/userWeb/installationOrder", params);
  },

  /**
   * 安装单-待签收-签收/拒签
   * @param {type} params 类型1签收2拒收
   * @returns
   */
  postInstallOrderReceiving(params) {
    return http.formPost("/userWeb/installationOrderReceiving", params);
  },

  /**
   * 安装单-服务中-预约
   * @param {*} params
   * @returns
   */
  installOrderAppointment(params) {
    return http.formPost("/userWeb/installationOrderAppointment", params);
  },
  backOrderAppointment(params) {
    return http.formPost("/userWeb/backOrderAppointment", params);
  },

  /**
   * 安装单-服务中-取消订单
   * @param {*} params
   * @returns
   */
  installOrderCancel(params) {
    return http.formPost("/userWeb/installationOrderCancel", params);
  },

  /**
   * 安装单-订单详情
   * @param {*} params
   * @returns
   */
  installOrderDetail(params) {
    return http.get("/userWeb/installationOrderDetail", params);
  },

  /**
   * 安装单-订单数据统计
   * @param {*} params
   * @returns
   */
  installOrderStatistics(params) {
    return http.get("/userWeb/installationOrderStatistics", params);
  },

  /**
   * 安装单-订单数据统计图
   * @param {*} params
   * @returns
   */
  installOrderData(params) {
    return http.get("/userWeb/installationOrderData", params);
  },

  /**
   * 安装单-留言
   * @param {*} params
   * @returns
   */
  installOrderComment(params) {
    return http.post("/userWeb/installationOrderComment", params);
  },

  /**
   * 安装单-评价列表
   * @param {*} params
   * @returns
   */
  getOrderEvaluation(params) {
    return http.get("/userWeb/orderEvaluation", params);
  },

  /**
   * 售卖单
   * @param {*} params
   * @returns
   */
  getOrder(params) {
    return http.get("/userWeb/order", params);
  },

  /**
   * 售卖单-订单详情
   * @param {*} params
   * @returns
   */
  getUWOrderDetail(params) {
    return http.get("/userWeb/orderDetail", params);
  },

  /**
   * 售卖单-物流查询
   * @param {*} params
   * @returns
   */
  getLogistics(params) {
    return http.get("/userWeb/logistics", params);
  },

  /**
   * 售卖单-申请退款/退货/换货
   * @param {*} params
   * @returns
   */
  refund(params) {
    return http.formPost("/userWeb/refund", params);
  },

  /**
   * 图片上传
   * @param {*} params
   * @returns
   */
  uploadImg(params) {
    return http.formDataPost("/userWeb/uploadImg", params);
  },

  /**
   * 文件上传
   * @param {*} params
   * @returns
   */
  uploadOtherFile(params) {
    return http.formDataPost("/userWeb/uploadOtherFile", params);
  },

  /**
   * 售卖单-物流信息提交
   * @param {*} params
   * @returns
   */
  refundLogistics(params) {
    return http.formPost("/userWeb/refundLogistics", params);
  },

  /**
   * 售卖单-完成订单
   * @param {*} params
   * @returns
   */
  completeOrder(params) {
    return http.formPost("/userWeb/completeOrder", params);
  },

  /**
   * 收货地址列表
   * @param {*} params
   * @returns
   */
  getConsigneeList(params) {
    return http.get("/userWeb/consigneeList", params);
  },

  /**
   * 收货地址修改
   * @param {*} params
   * @returns
   */
  consigneeEdit(params) {
    return http.post("/userWeb/consigneeEdit", params);
  },

  /**
   * 收货地址删除
   * @param {*} params
   * @returns
   */
  consigneeDelete(params) {
    return http.delete("/userWeb/consigneeDelete", params);
  },

  /**
   * 收货地址新增
   * @param {*} params
   * @returns
   */
  consigneeAdd(params) {
    return http.post("/userWeb/consigneeAdd", params);
  },

  /**
   * 收货地址默认
   * @param {*} params
   * @returns
   */
  consigneeDefault(params) {
    return http.formPost("/userWeb/consigneeDefault", params);
  },
  /**
   * 配件列表
   * @param {*} params
   * @returns
   */
  accessoriesOrderList(params) {
    return http.get(`/userWeb/accessoriesOrderList`, params);
  },
  /**
   *  申请状态
   * @param {*} params
   * @returns
   */
  installationOrderAccessories(params) {
    return http.post(`/userWeb/installationOrderAccessories`, params);
  },

  /**
   *  签约条款
   * @param {*} params
   * @returns
   */
  getSigningProcess(params) {
    return http.get(`/userWeb/getSigningProcess`, params);
  },

  // backOrderList
  /**
   *  返单列表
   * @param {*} params
   * @returns
   */
  backOrderList(params) {
    return http.get(`/userWeb/backOrderList`, params);
  },
  /**
   *  配件单详情
   * @param {*} params
   * @returns
   */
  accessoriesOrderDetail(params) {
    return http.get(`/userWeb/accessoriesOrderDetail`, params);
  },
  /**
   *  配件单详情
   * @param {*} params
   * @returns
   */
  signing(params) {
    return http.formPost(`/userWeb/signing`, params);
  },
  /**
   *  支付
   * @param {*} params
   * @returns
   */
  wxPay(params) {
    return http.formPost(`/userWeb/wxPay`, params);
  },
  /**
   *  支付
   * @param {*} params
   * @returns
   */
  installationOrderReturn(params) {
    return http.post(`/userWeb/installationOrderReturn`, params);
  },
  /**
   *  保证金账单
   * @param {*} params
   * @returns
   */
  customerMargin(params) {
    return http.get(`/userWeb/customerMargin`, params);
  },
  backOrderDetail(params) {
    return http.get(`/userWeb/backOrderDetail`, params);
  },
  getOrderMoney(params) {
    return http.get(`/userWeb/getOrderMoney`, params);
  },
  installationOrderComplete(params) {
    return http.formPost(`/userWeb/installationOrderComplete`, params);
  }
};
